import {
    Chart as ChartJS,
    LineElement,
    BarElement,
    CategoryScale,
    LinearScale,
    PointElement,
    Title,
    Tooltip,
    Legend,
    LineController,  // For Line chart
    BarController,   // Add BarController
    LogarithmicScale // Add LogarithmicScale
  } from "chart.js";
  
  // Register the necessary components for both Line and Bar charts
  ChartJS.register(
    LineController,  // Register LineController for line charts
    BarController,   // Register BarController for bar charts
    LogarithmicScale,  // Register LogarithmicScale to enable logarithmic scale
    LineElement,
    BarElement,
    CategoryScale,
    LinearScale,
    PointElement,
    Title,
    Tooltip,
    Legend
  );
  
  export default ChartJS;
  