import React, { useContext } from 'react';
import Grid from '@mui/material/Grid2';
import Box from '@mui/material/Box';
// import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import ChartUserByCountry from './ChartUserByCountry';
import SessionsChart from './SessionsChart';
import FinancialQuarterSCoreChart from './FinancialQuarterScoreChart';
import FsListTable from './FundFilterDataGrid';


export default function MainGrid({company}) {

  // const { selectedCompany, companyList, handleCompanySelect } = useContext(HomePageContext);

  return (
    <Box sx={{ width: '100%', maxWidth: { sm: '100%', md: '1700px' } }}>

      <Typography component="h2" variant="h6" sx={{ mb: 2 }}>
        Detailed Insights
      </Typography>
      <Grid container spacing={2} columns={12} sx={{ mb: (theme) => theme.spacing(2) }}>
{/* ----------------------------Company Info---------------------------------------------------------------- */}
      
        <Grid size={{ xs: 12, md: 6 }}>
          <SessionsChart />
        </Grid>
        <Grid size={{ xs: 12, md: 6 }}>
          <FinancialQuarterSCoreChart />
        </Grid>
      </Grid>
      <Typography component="h2" variant="h6" sx={{ mb: 2 }}>
        Details
      </Typography>
      <Grid container spacing={2} columns={12}>

        <Grid size={{ xs: 12, lg: 3 }}>
          <Stack gap={2} direction={{ xs: 'column', sm: 'row', lg: 'column' }}>
            <ChartUserByCountry />
          </Stack>
        </Grid>
      </Grid>
    </Box>
  );
}
