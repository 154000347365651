import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, ReferenceLine, Cell } from 'recharts';
import React from 'react';
import { Paper, Grid2, Grid, Typography } from '@mui/material';

const chartFields = [
    // { label: 'Average Volume', field: 'avg_volume' },
    { label: 'Average Turnover', field: 'avg_turnover' },
    { label: 'Avg No Of Transactions', field: 'avg_tot_trans' },
    { label: 'Average Delivery %', field: 'avg_del_perc' },
    { label: 'Average Delivery Quantity', field: 'avg_del_qty' },
    { label: 'Avg Quantity per Trade', field: 'avg_qty_per_trade' },
    { label: 'Avg Trade Worth', field: 'avg_trade_worth' },
];

const baseColors = {
    avg_volume: 'rgba(75, 192, 192, ',
    avg_turnover: 'rgb(46, 31, 52,',
    avg_tot_trans: 'rgb(101, 135, 161, ',
    avg_del_perc: 'rgb(8, 31, 92,',
    avg_del_qty: 'rgba(11, 44, 61,',
    avg_qty_per_trade: 'rgb(22, 42, 44,',
    avg_trade_worth: 'rgba(98, 37, 105, ',
};

// const lineColors = [
//     'rgba(75, 192, 192, 0.8)',
//     'rgba(255, 99, 132, 0.8)',
//     'rgba(54, 162, 235, 0.8)',
//     'rgba(255, 206, 86, 0.8)',
//     'rgba(255, 159, 64, 0.8)',
//     'rgba(153, 102, 255, 0.8)',
// ];


const horizontalLinePeriods = ["1_year"];



export const AveragesBarChartV2 = ({ data, barSize = 30 }) => {
    if (!data || !data.averages) {
        return
    }

    const periods = Object.keys(data.averages);

    const createChartData = (field) => {
        return periods.map(period => ({
            name: period,
            value: data.averages[period][field],
        }));
    };

    const generateShadedColors = (field, values) => {
        const maxVal = Math.max(...values);
        const minVal = Math.min(...values);
        return values.map(value => {
            const shade = 0.3 + 0.7 * ((value - minVal) / (maxVal - minVal));
            return `${baseColors[field]}${shade})`;
        });
    };
    const generateHorizontalLines = (field) => {
        return horizontalLinePeriods.map((period, index) => {
            const lineColor = `${baseColors[field]}0.9)`; // Use base color with a fixed opacity
            const lineWidth = (index % 6) + 2;

            return (
                <ReferenceLine
                    key={`${field}-${period}`}
                    y={data.averages[period][field]}
                    label={{
                        position: 'insideRight',
                        value: `${period.replace(/[_\s-]+(.)/g, (_, char) => char.toUpperCase()).replace(/[_\s-]/g, '')} Avg`,//: ${data.averages[period][field]}
                        fill: lineColor,
                        fontSize: 14,
                        dx: 10,
                        dy: -10
                    }}
                    stroke={lineColor}
                    strokeWidth={lineWidth}
                />
            );
        });
    };


    // const generateHorizontalLines = (field) => {
    //     return horizontalLinePeriods.map((period, index) => {
    //         const lineColor = lineColors[index % lineColors.length];
    //         const lineWidth = (index % 6) + 2;

    //         return (
    //             <ReferenceLine
    //                 key={`${field}-${period}`}
    //                 y={data.averages[period][field]}
    //                 label={{
    //                     position: 'insideRight',
    //                     value: `${period.replace(/[_\s-]+(.)/g, (_, char) => char.toUpperCase()).replace(/[_\s-]/g, '')} Avg: ${data.averages[period][field]}`,
    //                     fill: lineColor,
    //                     fontSize: 14,
    //                     dx: 10,
    //                     dy: -10
    //                 }}
    //                 stroke={lineColor}
    //                 strokeWidth={lineWidth}
    //             />
    //         );
    //     });
    // };

    return (
        <Grid
            container
            justifyContent="space-between"
            alignItems="stretch"
            // spacing={1}
            gap={1}
            paddingBottom={2}
            paddingTop={1}

        >


            {chartFields.map(({ label, field }) => {
                const chartData = createChartData(field);
                const values = chartData.map(data => data.value);
                const backgroundColors = generateShadedColors(field, values);
                return (
                    <Grid
                        item
                        xs={12}
                        md={5.8}
                        lg={3.8}
                        key={field}
                        // style={{ marginTop: 20, padding: 10 }}
                        // gap={1} 
                        sx={{
                            marginTop: 2,
                            // margin:1,
                            padding: 1,
                            borderRadius: .8,
                            border: '1px solid',
                            borderColor: 'divider',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                        }}
                    >

                        <Typography align='center'>{label}</Typography>
                        <ResponsiveContainer width="100%" height={300}>
                            <BarChart
                                data={chartData}
                                margin={{ top: 15, right: 20, left: 5, bottom: 5 }}
                            >
                                {/* <CartesianGrid strokeDasharray="3 3" stroke="#e0e0e0" /> */}
                                <CartesianGrid
                                    stroke="#e0e0e0"  // Light gray color for solid lines
                                    strokeWidth={1}    // Set thickness of the lines
                                    opacity={0.5}
                                    horizontal={true}  // Draw horizontal grid lines
                                    vertical={false}    // Draw vertical grid lines (default is true)
                                />
                                <XAxis
                                    dataKey="name"
                                    angle={-60}
                                    textAnchor="end"
                                    dy={10}
                                    tick={{ fontSize: 12, fill: '#757575' }}
                                />
                                <YAxis tick={{ fontSize: 12, fill: '#757575' }} />
                                <Tooltip contentStyle={{ backgroundColor: '#fff', borderRadius: '4px' }} />
                                <Legend wrapperStyle={{ paddingTop: 10, marginBottom: 20, position: 'relative', top: '20px' }} />
                                <Bar
                                    dataKey="value"
                                    radius={[1, 1, 0, 0]}
                                    barSize={barSize}
                                    fillOpacity={1}
                                >
                                    {chartData.map((entry, index) => (
                                        <Cell key={`cell-${index}`} fill={backgroundColors[index]} />
                                    ))}
                                </Bar>
                                {generateHorizontalLines(field)}
                            </BarChart>
                        </ResponsiveContainer>
                    </Grid>

                );
            })}
        </Grid>
    );
};

