import React from 'react';
import { AppBar, Toolbar, Typography, Box, Link } from '@mui/material';
import { styled } from '@mui/material/styles';
import CompanySearch from '../common/CompanySearch';
import { useContext } from 'react';
import HomePageContext from '../../context/HomePageContext';

const StyledAppBar = styled(AppBar)(({ theme }) => ({
  backgroundColor: 'rgba(255, 255, 255, 0.8)',
  backdropFilter: 'blur(10px)',
  boxShadow: 'none',
}));

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  display: 'flex',
  // justifyContent: 'space-between',
  justifyContent: 'space-evenly',
  alignItems: 'center',
  padding: '0 16px',
  height: '100%',
  padding: 0,                      // Remove padding to avoid misalignment
  minHeight: 'unset',
}));

const SearchContainer = styled(Box)(({ theme }) => ({
  flex: 1,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100%',
  maxWidth: '60%',
  minWidth: '200px',
  width: '20vw', // Dynamically adjust width to 20% of the viewport
  [theme.breakpoints.down('sm')]: {
    width: '50%', // Adjust width for smaller screens
  },
  // [theme.breakpoints.down('sm')]: {
  //   width: '50vw', // Increase width for smaller screens
  //   minWidth: '200px',
  // },
}));

const LeftSection = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  flex: 0.2, // Restrict space for left content
}));

const SearchHeaderBar = ({ companyList, handleSearchSelect }) => {

  const {
    // companyList,
    type
} = useContext(HomePageContext);


  return (
    <StyledAppBar position="sticky"

      sx={{
        // display: 'flex',
        // paddingTop: '8px',
        // paddingBottom: '8px',
        // boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
        height: {
          xs: '50px',
          sm: '60px', 
          md: '65px',
        },
      }}
    >
      <StyledToolbar
        sx={{
          width: '100%',
          display: 'flex',
          justifyContent: 'center', 
          alignItems: 'center',
          height: '100%',
        }}

      >
        <LeftSection>
          <Typography
            variant="h6"
            noWrap
            component={Link}
            // to={"/strategy-leaderboard"}
            sx={{
              mr: 2,
              display: { xs: 'flex', md: 'flex', lg: 'flex' },
              fontFamily: 'monospace',
              fontWeight: 700,
              letterSpacing: '.3rem',
              color: 'inherit',
              textDecoration: 'none',
            }}
          >
            Zinvest
          </Typography>
        </LeftSection>

        <SearchContainer>
          <CompanySearch 
          handleSearchSelect={handleSearchSelect} 
          companyList={companyList} 
          type={type}
          />
        </SearchContainer>
        <Box sx={{ flex: 1 }} />
      </StyledToolbar>
    </StyledAppBar>
  );
};

export default SearchHeaderBar;

