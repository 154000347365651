import React, { useState, useEffect, useRef, useMemo } from "react";
import { useAuthContext } from './context/AuthContext';
import ResponsiveAppBar from "./components/Appheader/AppBar";
import SideBar from "./components/common/SideBar";
import { CircularProgress, Box, useMediaQuery } from "@mui/material";
import CssBaseline from '@mui/material/CssBaseline';
import { getToken } from "./utils/helpers";
import AppTheme from "./theme/AppTheme";
import { styled } from '@mui/material/styles';
import AppRoutes from "./Routes";
import { chartsCustomizations, dataGridCustomizations, datePickersCustomizations, treeViewCustomizations } from "./theme/customizations";
import AuthProvider from "./components/AuthProvider/AuthProvider";
import HomePageContext from "./context/HomePageContext";
import { fetchNseData, fetchAverageData, fetchAllinst, fetchAllIndex, fetchIndexData } from './utils/services';
import StickyHeader from "./components/common/StickyHeader";
import SearchHeaderBar from "./components/Appheader/SearchHeader";
import { CompanySearchProvider, useCompanySearch } from "./context/CompanySearchContext";

const xThemeComponents = {
  ...chartsCustomizations,
  ...dataGridCustomizations,
  ...datePickersCustomizations,
  ...treeViewCustomizations,
};

const drawerWidthmin = 31;
const drawerWidth = 150;
const drawerCollapsedWidth = 60;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open, isDesktop }) => ({
    flexGrow: 1,
    marginTop: 0,
    padding: theme.spacing(1),
    marginLeft: open && isDesktop ? `${drawerWidthmin}px` : 0,
    width: open && isDesktop ? `calc(100% - ${drawerWidthmin}px)` : '100%',
    transition: theme.transitions.create(['margin', 'width'], {
      easing: open ? theme.transitions.easing.easeOut : theme.transitions.easing.sharp,
      duration: open ? theme.transitions.duration.enteringScreen : theme.transitions.duration.leavingScreen,
    }),
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  })
);

const App = () => {
  const { user, isLoading } = useAuthContext();
  const [openSidebar, setOpen] = useState(false);
  const [showAppBar, setShowAppBar] = useState(true);
  const companySearchRef = useRef(null);
  const analyticsRef = useRef(null);

  const [companyList, setCompanyList] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState('');
  const [averagesData, setAvgData] = useState([]);
  const [nseCompanyData, setNseCompanyData] = useState([]);

  const [indexList, setIndexList] = useState([]);
  const [selectedIndex, setSelectedIndex] = useState('');
  const [indexData, setIndexData] = useState([]);
  const [type, setType] = useState('stock');
  
  const authToken = getToken();
  const startDate = '2024-10-01';
  const endDate = '2024-12-31';
  // const isDesktop = useMediaQuery((theme) => theme.breakpoints.up('md'));
  const isDesktop = useMediaQuery('(min-width: 960px)');
  const [isCompanyListLoading, setIsCompanyListLoading] = useState(true);

  const handleScroll = () => {
    if (companySearchRef.current) {
      const companySearchTop = companySearchRef.current.getBoundingClientRect().top;
      if (companySearchTop <= 0) {
        setShowAppBar(false);
      } else {
        setShowAppBar(true);
      }
    }

    if (analyticsRef.current) {
      const analyticsTop = analyticsRef.current.getBoundingClientRect().top;
      if (analyticsTop <= 0) {
        setShowAppBar(false);
      } else {
        setShowAppBar(true);
      }
    }
  };


  const getCompanyList = async () => {
    try {
      const companies = await fetchAllinst(authToken);
      setCompanyList(companies);
      const indices = await fetchAllIndex(authToken);
      setIndexList(indices);
    } catch (error) {
      console.error('Failed to fetch list:', error);
    }
    finally {
      setIsCompanyListLoading(false);
    }
  }

  const handleSearchSelect = (company,type) => {
    // if (!company?.isin || !authToken)
    //   return;
    if(type==='stock'){
      setSelectedCompany(company);
      fetchNseData(authToken, company.isin, startDate, endDate)
      .then((data) => setNseCompanyData(data))
      .catch((error) => console.error('Error fetching NSE data:', error));

      fetchAverageData(authToken, company.isin)
      .then((data) => setAvgData(data))
      .catch((error) => console.error('Error fetching average data:', error));
    }
    else{
      setSelectedIndex(company);
      fetchIndexData(authToken,company.symbol,startDate,endDate)
      .then((data) => setIndexData(data.data))
      .catch((error) => console.error('Error fetching index data:', error));
    }


  };
  useEffect(() => {
    getCompanyList();
  }, []);


  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  if (isLoading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '100vh' }}>
        <CircularProgress size={60} />
      </Box>
    );
  }

  return (
    <AuthProvider>
      <HomePageContext.Provider value={{
        selectedCompany,
        selectedIndex,
        indexList,
        indexData,
        setSelectedCompany,
        setSelectedIndex,
        companyList,
        handleSearchSelect,
        averagesData,
        nseCompanyData,
        type,
        setType
      }}>

        <CompanySearchProvider>
          <AppTheme themeComponents={xThemeComponents}>
            <CssBaseline enableColorScheme />
            <ResponsiveAppBar
              toggleDrawer={() => setOpen(!openSidebar)}
              open={openSidebar}
              drawerWidth={drawerCollapsedWidth}
              drawerCollapsedWidth={drawerCollapsedWidth}
              showAppBar={showAppBar}
            />

            <StickyHeader
              position={150}
              // position={companySearchRef} 
              openSidebar={openSidebar}
              drawerWidth={openSidebar ? drawerCollapsedWidth : 0}
            >
              <SearchHeaderBar
                handleSearchSelect={handleSearchSelect}
                companyList={ type==='stock' ? companyList:indexList}
              />
            </StickyHeader>

            <SideBar open={openSidebar} drawerWidth={drawerWidth} drawerCollapsedWidth={drawerCollapsedWidth} />

            <Main open={openSidebar} isDesktop={isDesktop} >
              <AppRoutes />
            </Main>
          </AppTheme>
        </CompanySearchProvider>
      </HomePageContext.Provider>
    </AuthProvider>
  );
};

export default App;
