
import { API } from './constant.js';
import axios from 'axios';

const apiRequest = async (method, endpoint, authToken, params = {}, data = {},timeout=10000) => {
  const url = `${API}${endpoint}`;
  console.log(`Making ${method} request to:`, url);

  try {
    const response = await axios({
      method,
      url,
      params,
      data,
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
      timeout: timeout,
    });
    // console.log("resp-data-inst", response);
    return response.data;
  } catch (error) {
    handleAPIError(error);
    throw error;
  }
};

export const fetchAllinst = async (authToken) => {
  return await apiRequest('GET', `/inst-data/all-inst`, authToken);
};

export const fetchNseData = async (authToken, isin, startDate = null, endDate = null) => {
  const params = {};
  if (startDate) {
    params.start_date = startDate;
  }
  if (endDate) {
    params.end_date = endDate;
  }
  try {
    return await apiRequest('GET', `/nse-data/isin/${isin}`, authToken, params);
  } catch (error) {
    console.error('Error fetching instance data:', error);
    throw error;
  }
};

export const fetchAverageData = async (authToken, isin, years = 3, startDate = null, endDate = null) => {

  const params = {};
  if (startDate && endDate) {
    params.start_date = startDate;
    params.end_date = endDate;
  }
  else {
    params.years = years;
  }
  try {
    return await apiRequest('GET', `/nse-data/average-data/${isin}`, authToken, params);
  } catch (error) {
    console.error('Error fetching instance data:', error);
    throw error;
  }

};

export const fetchAverageScore = async (authToken, symbol, finYear) => {
  console.log("finyear",finYear);
  const year = finYear ?? new Date().getFullYear() - 2;
  const params = {
    symbol: symbol,
    finYear: year
  };
  console.log("params", params);
  try {
    return await apiRequest('GET', `/inst-data/get-average-score`, authToken, params);
  } catch (error) {
    console.error('Error fetching AverageScores:', error);
    throw error;
  }
};

export const getFSList = async (authToken,finYear=null,minScore=null) => {

  const params={};
  try {
    return await apiRequest('GET', `/fs-company/get-company-list`, authToken, params);
  } catch (error) {
    console.error('Error fetching Company score list(getFSList):', error);
    throw error;
  }
};

export const getTSList = async (authToken,minPerc,finYear=null,minScore=null) => {

  const params={};
  try {
    return await apiRequest('GET', `/ts-company/get-company-list`, authToken, params);
  } catch (error) {
    console.error('Error fetching Company Tech list(getTSList):', error);
    throw error;
  }
};


export const fetchAiInsights = async (authToken, isin) => {
  const params = {};
  const data={},timeout=30000;
  params.isin = isin;
  try {
    return await apiRequest('GET', `/inst-data/get-ai-analysis`, authToken, params,data,timeout);
  } catch (error) {
    console.error('Error fetching Ai insights:', error);
    throw error;
  }
};

export const fetchAllIndex = async (authToken) => {
  const tableName = 'index_datas';
  return await apiRequest('GET', `/table-meta?tableName=${tableName}`, authToken);
};

export const fetchIndexData = async (authToken, index_name, from_date, to_date) => {
  const params = {};
  const data = {};
  const timeout = 30000;
  params.index_name = index_name;
  params.from_date = from_date;
  params.to_date = to_date;
  try {
    return await apiRequest('GET', '/index-data', authToken, params, data, timeout);
  } catch (error) {
    console.error('Error fetching index data:', error);
    throw error;
  }
};

// http://localhost:1343/api/index-data?index_name=Nifty%2050&from_date=2022-01-01&to_date=2024-12-31

const handleAPIError = (error) => {
  if (error.response) {
    console.error('Response status:', error.response.status);
    console.error('Response data:', error.response.data);
  } else if (error.request) {
    console.error('No response received:', error.request);
  } else {
    console.error('Error:', error.message);
  }
};



