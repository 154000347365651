import React, { useState, useEffect, useContext } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Chip from '@mui/material/Chip';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import { BarChart } from '@mui/x-charts/BarChart';
import { useTheme } from '@mui/material/styles';
import { fetchAverageScore } from '../../utils/services';
import HomePageContext from '../../context/HomePageContext';
import { getToken } from '../../utils/helpers';
import { Box, minHeight } from '@mui/system';

const convertToFinancialQuarter = (qendDate) => {
  const date = new Date(qendDate);
  const month = date.getMonth();
  const year = date.getFullYear();
  const financialYear = month < 3 ? year - 1 : year;
  const quarter = Math.floor(((month + 9) % 12) / 3) + 1; // Financial quarter calculation
  return `${financialYear}-Q${quarter}`;
};

export default function FinancialQuarterScoreChart() {
  const authToken = getToken();
  const theme = useTheme();
  const colorPalette = [
    theme.palette.primary.dark,
    theme.palette.primary.main,
    theme.palette.primary.light,
  ];

  const [Scores, setScores] = useState([]);
  const { selectedCompany } = useContext(HomePageContext);
  const finYear = new Date().getFullYear() - 8;

  const processedData = Scores.map((entry) => ({
    quarter: convertToFinancialQuarter(entry.qend_date),
    score: entry.score,
    qend_date: new Date(entry.qend_date),
  })).sort((a, b) => a.qend_date - b.qend_date);


  useEffect(() => {
    if (!selectedCompany?.symbol) return;

    const fetchData = async () => {
      try {
        const data = await fetchAverageScore(authToken, selectedCompany.symbol, finYear);
        setScores(data?.quarterlyScores || []);
      } catch (error) {
        console.error('Error fetching scores:', error);
        setScores([]);
      }
    };
    fetchData();
  }, [selectedCompany, finYear]);

  return (
    <Card variant="outlined" sx={{ width: '100%', height: '100%' }}>
      <CardContent>
        <Typography component="h2" variant="subtitle2" gutterBottom>
          Financial Quarter Scores
        </Typography>
        <Stack sx={{ justifyContent: 'space-between' }}>
          <Stack
            direction="row"
            sx={{ alignItems: 'center', gap: 1 }}
          >
            <Typography variant="h4" component="p">
              Quarterly Scores
            </Typography>
            <Chip size="small" color="primary" label="Trend" />
          </Stack>
          <Typography variant="caption" sx={{ color: 'text.secondary' }}>
            Financial quarters and their respective scores
          </Typography>
        </Stack>
        {processedData.length > 0 ? (
          <BarChart
            borderRadius={8}
            colors={colorPalette}
            xAxis={[
              {
                scaleType: 'band',
                categoryGapRatio: 0.5,
                data: processedData.map((item) => item.quarter),
              },
            ]}
            series={[
              {
                id: 'scores',
                label: 'Scores',
                data: processedData.map((item) => item.score),
              },
            ]}
            height={250}
            margin={{ left: 50, right: 0, top: 20, bottom: 20 }}
            grid={{ horizontal: true }}
            slotProps={{
              legend: { hidden: true },
            }}
          />
        ) : (
          <Box sx={{ minHeight: 250 }}>
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              No data available.{"\n"}
              Search a Company.
            </Typography>
          </Box>

        )}
      </CardContent>
    </Card>
  );
}
