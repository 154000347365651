import React, { useState } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import MenuIcon from '@mui/icons-material/Menu';
import Button from '@mui/material/Button';
// import Tooltip from '@mui/material/Tooltip';
import { Link, useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { useAuthContext } from "../../context/AuthContext";
import SignUpPopup from '../../pages/SignUp/SignUp';
import { message } from "antd";
import { removeToken } from '../../utils/helpers';
import ColorModeIconDropdown from '../../theme/ColorModeIconDropdown';
import { styled, useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

const pages = ['Home', 'ZinvestPro'];
// const pages = ['Home', 'ZinvestPro','AlgoTrader'];
// const settings = ['Profile', 'Account', 'Logout'];

const StyledAppBar = styled(AppBar, {
  shouldForwardProp: (prop) => prop !== 'open' && prop !== 'drawerWidth' && prop !== 'drawerCollapsedWidth',
})(({ theme, open, drawerWidth, drawerCollapsedWidth, isDesktop }) => ({
  marginLeft: isDesktop && open ? `${drawerWidth}px` : `${0}px`,
  width: isDesktop && open
    ? `calc(100% - ${drawerCollapsedWidth}px)`
    : `calc(100% - ${0}px)`,
  transition: theme.transitions.create(['margin', 'width'], {
    easing: open ? theme.transitions.easing.easeOut : theme.transitions.easing.sharp,
    duration: open ? theme.transitions.duration.enteringScreen : theme.transitions.duration.leavingScreen,
  }),
}));


function ResponsiveAppBar({ toggleDrawer, open, drawerWidth, drawerCollapsedWidth, showAppBar }) {
  // const [sidebarOpen, setSidebarOpen] = useState(false); 
  const theme = useTheme();
  const textColor = theme.palette.mode === 'light' ? 'white' : 'white';

  const location = useLocation();
  const navigate = useNavigate();
  const { user, setUser } = useAuthContext();
  const [openSignup, setOpen] = useState(false);
  const isDesktop = useMediaQuery((theme) => theme.breakpoints.up('md'));

  // const [anchorElUser, setAnchorElUser] = React.useState(null);

  // const handleOpenUserMenu = (event) => {
  //   setAnchorElUser(event.currentTarget);
  // };
  // const toggleSidebar = () => {
  //   // console.log("clicked in appbar", open);
  //   toggleDrawer(!open);
  // };


  const handleLogout = () => {
    removeToken();
    setUser(undefined);
    message.success(`Good Bye ${user.username}!`);
    navigate("/signin", { replace: true });
  };

  const isAppBarHidden = location.pathname === '/dashboard';
  if (isAppBarHidden) return null;


  const handlePageClick = (page) => {
    if (page.toLowerCase() === 'ZinvestPro' && !user) {
      setOpen(true);
    } else {
      // Navigate to the page if user is logged in or not Analytics page
      navigate(`/${page.toLowerCase()}`);
    }
  };

  return (
    <StyledAppBar
      position="static"
      open={open}
      drawerWidth={drawerWidth}
      drawerCollapsedWidth={drawerCollapsedWidth}
      isDesktop={isDesktop}
      sx={{
        height: {
          xs: '50px', // Small screens
          sm: '60px', // Medium screens
          md: '65px', // Large screens
          lg: '65px', // Extra-large screens
        },
      }}
    >
      {isDesktop &&
        <Toolbar disableGutters sx={{ display: 'flex', justifyContent: 'space-between', paddingX: 2 }}>
          <Box sx={{ display: 'flex', lineHeight: '48px', margin: 1 }}>
            <IconButton
              size="medium"
              aria-label="open navigation"
              onClick={e => toggleDrawer(true)}
              sx={{
                color: 'inherit',
                // backgroundColor: 'none',
              }}

            >
              <MenuIcon />
            </IconButton>
          </Box>
          <Typography
            variant="h6"
            noWrap
            component={Link}
            sx={{
              mr: 2,
              display: { xs: 'none', md: 'flex' },
              fontFamily: 'monospace',
              fontWeight: 700,
              letterSpacing: '.3rem',
              color: 'inherit',
              textDecoration: 'none',
            }}
          >
            Zinvest
          </Typography>
          {/* <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' }, gap: 2 }}>
            {pages.map((page) => (
              <Button key={page} component={Link} to={`/${page.toLowerCase()}`}
                sx={{ color: textColor, my: 2 }}
              >
                {page}
              </Button>
            ))}
          </Box> */}
          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' }, gap: 2 }}>
            {pages.map((page) => (
              <Button
                key={page}
                onClick={() => handlePageClick(page)}  // Call handlePageClick when a page is clicked
                sx={{ color: textColor, my: 2 }}
              >
                {page}
              </Button>
            ))}

            {!user && openSignup && (
              <SignUpPopup open={openSignup} setOpen={setOpen} />
            )}
          </Box>
          <Box sx={{ flexGrow: 0 }}>
            {user ? (
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                {/* <Tooltip title="Open settings">
                  <IconButton onClick={handleOpenUserMenu} sx={{ p: 1 }}>
                    <Avatar alt={`${user.username}`} />
                  </IconButton>
                </Tooltip> */}
                <Button className="auth_button_username"
                  sx={{ color: textColor }}
                  component={Link} to="/profile">
                  <Typography>
                    Hi,{user.username}
                  </Typography>
                </Button>
                <Button
                  className="auth_button_logout"
                  sx={{ color: textColor }}
                  onClick={handleLogout}>
                  <Typography>


                    Logout
                  </Typography>
                </Button>
              </Box>
            ) : (
              <Box sx={{ display: 'flex', gap: 2 }}>
                <Button className="auth_button_signUp"
                  sx={{ color: textColor }}
                  onClick={() => setOpen(true)}>
                  SignUp
                </Button>
                <SignUpPopup open={openSignup} setOpen={setOpen} />
              </Box>
            )}
          </Box>
          <ColorModeIconDropdown />
        </Toolbar>
      }
      {!isDesktop && (
        <Toolbar disableGutters sx={{ display: 'flex', justifyContent: 'space-between', paddingX: 2 }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
            <Typography
              variant="h6"
              noWrap
              component={Link}
              sx={{
                mr: 2,
                fontFamily: 'monospace',
                fontWeight: 700,
                letterSpacing: '.3rem',
                color: 'inherit',
                textDecoration: 'none',
                display: 'flex', // Ensure it is visible on mobile
                flexGrow: 1, // Allow it to take available space
              }}
            >
              Zinvest
            </Typography>

            {user ? (
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>

                {/* <Button className="auth_button_username"
                  sx={{ color: textColor }}
                  component={Link} to="/profile">
                  <Typography>
                    Hi,{user.username}
                  </Typography>
                </Button> */}

                <Button
                  className="auth_button_logout"
                  sx={{ color: textColor }}
                  onClick={handleLogout}>
                  <Typography>
                    Logout
                  </Typography>
                </Button>
              </Box>
            ) : (
              <Box sx={{ display: 'flex', gap: 2 }}>
                <Button
                  className="auth_button_signUp"
                  onClick={() => setOpen(true)}
                  sx={{ color: textColor }}
                >
                  SignUp
                </Button>
                <SignUpPopup open={openSignup} setOpen={setOpen} />
              </Box>
            )}
          </Box>
          <ColorModeIconDropdown />
        </Toolbar>
      )}

      {/* {!user&&<SignUpPopup open={openSignup} setOpen={setOpen} />} */}

    </StyledAppBar>
  );
}

export default ResponsiveAppBar;
