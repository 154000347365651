import React from "react";
import { Container } from "@mui/system";
import IndexScreenerChart from "../../components/Chart/IndexScreenerChart";
const TestPage = () => {
  // const theme = useTheme();
  return (
    <Container
      maxWidth={false}
      disableGutters
      sx={{
        width: '100%',
        padding: 3,
        margin: 0,
        // marginTop: theme.spacing(8),
      }}
    >
      <IndexScreenerChart/>
    </Container>

  );
};

export default TestPage;
