import { useEffect, useState} from 'react';
import Chip from '@mui/material/Chip';
import { DataGrid } from '@mui/x-data-grid';
import { getFSList } from '../../utils/services';
import { getToken } from '../../utils/helpers';
import { Box, Typography,IconButton, Tooltip } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';


function renderStatus(status) {
  const colors = {
    Weak: 'error',
    Strong: 'success',
  };

  return <Chip label={status} color={colors[status]} size="small" />;
}

const renderAvatar = (params) => {
  const value = params.value || '';
  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <img
        src={`https://avatars.dicebear.com/api/initials/${value.toUpperCase()}.svg`}
        alt={value}
        style={{ width: 32, height: 32, borderRadius: '50%', marginRight: 8 }}
      />
      {value}
    </div>
  );
};

const FsListTable = () => {
  const [rows, setRows] = useState([]);
  const authToken = getToken();
  const [filterModel, setFilterModel] = useState({
    items: [
      {
        columnField: 'totalAverageScore',
        operatorValue: '>',
        value: 5,
      },
    ],
  });

  const columns = [
    {
      field: 'symbol',
      headerName: 'Symbol',
      flex: 1.5,
      minWidth: 150,
      renderCell: (params) => renderAvatar(params),
    },
    {
      field: 'totalAverageScore',
      headerName: 'Total Avg. Score',
      flex: 1,
      minWidth: 150,
      align: 'right',
      headerAlign: 'right',
    },
    {
      field: 'ttmAverageScore',
      headerName: 'TTM Avg. Score',
      flex: 1,
      minWidth: 150,
      align: 'right',
      headerAlign: 'right',
    },
    {
      field: 'lastQuarterScore',
      headerName: 'Last Quarter Score',
      flex: 1,
      minWidth: 150,
      align: 'right',
      headerAlign: 'right',
    },
    {
      field: 'status',
      headerName: 'Status',
      flex: 0.5,
      minWidth: 80,
      renderCell: (params) => renderStatus(params.value),
    },
  ];

  const handleFilterModelChange = (newFilterModel) => {
    // Compare the new filter model with the current one
    if (JSON.stringify(newFilterModel) !== JSON.stringify(filterModel)) {
      setFilterModel(newFilterModel); // Only update if different
    }
  };
  const fetchData = async () => {
    
    try {
      const data = await getFSList(authToken);
      const mappedRows = data.map((company) => ({
        id: company.symbol,
        symbol: company.symbol,
        totalAverageScore: company.t_avgscore,
        ttmAverageScore: company.ttm_avgscore,
        lastQuarterScore: company.l_qrtr_score,
        status: (company.ttm_avgscore > 7.5 || (company.l_qrtr_score >= 7 && company.t_avgscore > 6)
          || (company.ttm_avgscore > 6.4 && company.l_qrtr_score >= 7))
          ? 'Strong'
          : 'Weak',
      }));

      setRows(mappedRows);
    } catch (error) {
      console.error('Error fetching scores:', error);
      setRows([]);
    }
  };

  useEffect(() => {
    if (!rows.length) {
      fetchData();
    }
  }, []);

  return (
    <Box>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        sx={{
          paddingBottom: 1,
        }}
      >
        <Box flex="1" textAlign="right" display="flex" alignItems="center" justifyContent="flex-end">
          <Typography variant="subtitle2">
            Zinvest Score
          </Typography>
          <Tooltip title="Zinvest Score is calculated out of 10, based on fundamental datas of various matrices.As of Now Banking Stocks are not accurateand cannot be depended for taking informed decisions">
            <IconButton
              size="small"
              sx={{
                backgroundColor: 'transparent',
                padding: 0,
                marginLeft: 0.5,
              }}
            >
              <InfoOutlinedIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        </Box>
      </Box>


      <DataGrid
        autoHeight
        checkboxSelection
        rows={rows}
        columns={columns}
        getRowClassName={(params) =>
          params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
        }
        initialState={{
          pagination: { paginationModel: { pageSize: 20 } },
        }}
        pageSizeOptions={[10, 20, 50]}
        disableColumnResize
        density="compact"
        slotProps={{
          filterPanel: {
            filterFormProps: {
              logicOperatorInputProps: {
                variant: 'outlined',
                size: 'small',
              },
              columnInputProps: {
                variant: 'outlined',
                size: 'small',
                sx: { mt: 'auto' },
              },
              operatorInputProps: {
                variant: 'outlined',
                size: 'small',
                sx: { mt: 'auto' },
              },
              valueInputProps: {
                InputComponentProps: {
                  variant: 'outlined',
                  size: 'small',
                },
              },
            },
          },
        }}
        filterModel={filterModel} // Apply the filter model here
        onFilterModelChange={handleFilterModelChange} // Update filter model state
      />
    </Box>
  );
};

export default FsListTable;
