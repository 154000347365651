import React, { useState, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
// import { useAuthContext } from "../../context/AuthContext";
import { useAuthContext } from "../../context/AuthContext";
import { message, Spin } from "antd";
import { API } from "../../utils/constant";
import { getToken } from '../../utils/helpers';

const UserStrategyForm = ({ fabClicked ,onClose}) => {
    const [strategy, setStrategy] = useState('');
    const [strategyid, setStrategyId] = useState('');
    const [allocation, setAllocation] = useState('');
    const [overrideData, setOverrideData] = useState('');
    const [userBrokerAccount, setUserBrokerAccount] = useState('');
    const [status, setStatus] = useState('active');
    const [indices, setIndices] = useState('nifty');

    const [loading, setLoading] = useState(false);
    const [coreStrategies, setCoreStrategies] = useState('');
    const { user} = useAuthContext();

    const setStrategyData = (event) => {
        event.preventDefault();
        const selectedValue = event.target.value;
        setStrategy(selectedValue);
        const strategydata = coreStrategies.find(item => item.attributes.name === selectedValue);
        // console.log('strategy data',strategydata)
        setStrategyId(strategydata.id);

        if (strategydata) {
            const override_data = JSON.stringify(strategydata.attributes.data, null, 2);
            // console.log('override', override_data);
            setOverrideData(override_data);
        } else {
            console.log('No strategy data found for the selected value');
        }
    }

    const handleSubmit = async (event) => {
        event.preventDefault();

        // Handle form submission here, e.g., sending data to backend
        // console.log("user",user)
        const formData = {
            strategy:strategyid,
            allocation,
            override_data: JSON.parse(overrideData),
            user_broker_account: parseInt(userBrokerAccount),
            user:user.id,
            status,
            indices,
        };
        console.log(formData); // For demonstration, you can send this data to backend
        // console.log("user det",user)

        const payload = {data:formData};
        try {
            const authToken = getToken();
            const response = await fetch(`${API}/app-user-strategies`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${authToken}`,
                },
                body: JSON.stringify(payload),
            });

            if (response.ok) {
                message.success("Strategy Created successfully!");
            } else {
                console.error(`Error: ${response.status} - ${response.statusText}`);
                message.error("Failed to Create strategy!");
            }
        } catch (error) {
            console.error(error);
            message.error("Error while updating strategy!");
        } finally {
            setLoading(false);
            onClose();
        }



    };
    const fetchData = async () => {
        setLoading(true);
        try {
            const authToken = getToken();
            const response = await fetch(`${API}/app-core-strategies?sort=name:asc`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${authToken}`,
                },
            });

            if (response.ok) {
                const responseData = await response.json();
                // console.log("console core-strategies", responseData.data)
                if (responseData.data.length === 0) {
                    message.info("No Core strategies available.");
                }
                else {

                    setCoreStrategies(responseData.data); // Set user strategies
                    // message.success("Data fetched successfully!");
                }
            } else {
                console.error(`Error: ${response.status} - ${response.statusText}`);
                // message.error("Failed to fetch data!");
            }
        } catch (error) {
            console.error(error);
            // message.error("Error while fetching data!");
        } finally {
            setLoading(false);
        }
    };
    // event.preventDefault();
    useEffect(() => {
        fetchData();
    }, [fabClicked]);
    if (loading) {
        return <Spin size="large" />;
    }

    // };


    return (
        <div style={{ maxHeight: '80vh', overflow: 'auto' }}>
            <form onSubmit={handleSubmit}>

                <FormControl fullWidth margin="normal">
                    <InputLabel>Core Strategy</InputLabel>
                    <Select
                        value={strategy}
                        onChange={setStrategyData}
                    >
                        {/* {console.log("core Strategies", coreStrategies)} */}
                        {coreStrategies &&
                            coreStrategies.map((strategy, index) => (
                                <MenuItem key={index} value={strategy.attributes.name}>{strategy.attributes.name}</MenuItem>
                            ))}

                    </Select>
                </FormControl>



                <TextField
                    label="Allocation"
                    type="number"
                    value={allocation}
                    onChange={(e) => setAllocation(e.target.value)}
                    fullWidth
                    margin="normal"
                />

                <TextField
                    label="Override Data"
                    value={overrideData}
                    disabled={true}
                    multiline={true}
                    // onChange={(e) => setOverrideData(e.target.value)}
                    fullWidth
                    margin="normal"
                />
                <TextField
                    label="User Broker Account"
                    value={userBrokerAccount}
                    onChange={(e) => setUserBrokerAccount(e.target.value)}
                    fullWidth
                    margin="normal"
                />
                <FormControl fullWidth margin="normal">
                    <InputLabel>Status</InputLabel>
                    <Select
                        value={status}
                        onChange={(e) => setStatus(e.target.value)}
                    >
                        <MenuItem value="active">Active</MenuItem>
                        <MenuItem value="inactive">Inactive</MenuItem>
                    </Select>
                </FormControl>

                <FormControl fullWidth margin="normal">
                    <InputLabel>Indices</InputLabel>
                    <Select
                        value={indices}
                        required={true}
                        onChange={(e) => setIndices(e.target.value)}
                    >
                        <MenuItem value="nifty">Nifty</MenuItem>
                        <MenuItem value="bank_nifty">Nifty Bank</MenuItem>
                        <MenuItem value="sensex">Sensex</MenuItem>
                        <MenuItem value="fin_nifty">Fin Nifty</MenuItem>
                        <MenuItem value="midcpnifty">Mid Cap Nifty</MenuItem>
                    </Select>
                </FormControl>
                <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                >
                    Submit
                </Button>
            </form>
        </div>
    );
};

export default UserStrategyForm;
