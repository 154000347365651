// components/CompanySearch.js
import React, { useState, useEffect, useMemo, useCallback, useRef } from 'react';
import { TextField, MenuItem, Typography, Box, useTheme } from '@mui/material';
import { debounce } from 'lodash';
import { FixedSizeList as List } from 'react-window';
import InputAdornment from '@mui/material/InputAdornment';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import { useLocation } from 'react-router-dom';

import { useCompanySearch } from '../../context/CompanySearchContext';

const CompanySearch = ({ handleSearchSelect, companyList, type }) => {
    const location = useLocation();

    const { 
        searchQuery, setSearchQuery, 
        companySymbol, setCompanySymbol,
        ISIN,setCompanyISIN
    } = useCompanySearch();
    
    const [filteredCompanies, setFilteredCompanies] = useState([]);
    const [openDropdown, setOpenDropdown] = useState(false);
    const theme = useTheme();
    const dropdownRef = useRef(null);

    const debouncedSetSearchQuery = useMemo(
        () => debounce((query) => setSearchQuery(query), 50),
        []
    );
    const handleSelect = (company) => {
        handleSearchSelect(company, type);
        setSearchQuery("");
        setCompanySymbol(company?.symbol);
        setCompanyISIN(company?.isin);
        setFilteredCompanies([]);
        setOpenDropdown(false);
    };

    const handleSearchChange = (e) => {
        const value = e.target.value;
        setSearchQuery(value);
        setOpenDropdown(true);
        debouncedSetSearchQuery(value);
    };
    const handleBackdropClick = (e) => {
        if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
            setOpenDropdown(false);
        }
    };

    useEffect(() => {
        if (location.pathname === '/home' && type==='index') {
            setSearchQuery('');
            setCompanySymbol('');
            setFilteredCompanies([]);
            setOpenDropdown(false);
        }
    }, [type]);


    useEffect(() => {
        if (openDropdown) {
            document.addEventListener('mousedown', handleBackdropClick);
        } else {
            document.removeEventListener('mousedown', handleBackdropClick);
        }
        return () => document.removeEventListener('mousedown', handleBackdropClick);
    }, [openDropdown]);

    useEffect(() => {
        if (searchQuery) {
            const lowerQuery = searchQuery.toLowerCase();
            const scoredCompanies = companyList.map((company) => {
                const symbolMatch = company.symbol.toLowerCase().startsWith(lowerQuery) ? 1 : 0;
                const nameMatch = company.name.toLowerCase().startsWith(lowerQuery) ? 1 : 0;
                return { ...company, score: symbolMatch + nameMatch };
            });
            const filtered = scoredCompanies
                .filter(
                    (company) =>
                        company.symbol.toLowerCase().includes(lowerQuery) ||
                        company.name.toLowerCase().includes(lowerQuery)
                )
                .sort((a, b) => b.score - a.score);
            setFilteredCompanies(filtered);
        } else {
            setFilteredCompanies([]);
            setOpenDropdown(false);
        }
    }, [searchQuery]);


    const Row = useCallback(
        ({ index, style }) => (
            <MenuItem
                style={style}
                key={`${filteredCompanies[index].isin}-${filteredCompanies[index].exch}`}
                onClick={() => handleSelect(filteredCompanies[index])}
            >
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                    <Typography variant="body1" style={{ fontWeight: 'bold' }}>
                        {filteredCompanies[index].symbol}
                    </Typography>
                    <Typography variant="body2" style={{ color: 'gray' }}>
                        {filteredCompanies[index].name} ({filteredCompanies[index].exch})
                    </Typography>
                </Box>
            </MenuItem>
        ),
        [filteredCompanies]
    );

    return (
        <Box
            sx={{
                position: 'relative',
                width: '100%',
                boxShadow: 2,
                borderRadius: '8px',
            }}
        >
            <TextField
                fullWidth
                placeholder={
                    companySymbol
                        ? `${companySymbol}`
                        : `Search ${type ? type : 'company'} name or symbol`
                }
                variant="outlined"
                id="search"
                value={searchQuery}
                onChange={handleSearchChange}
                slotProps={{
                    input: {
                        endAdornment: (
                            <InputAdornment position="end">

                                <SearchRoundedIcon fontSize="medium" sx={{ color: "rgb(170, 170, 170)" }} />

                            </InputAdornment>
                        ),
                        sx: {
                            paddingLeft: "10px",
                            paddingRight: "10px",
                            backgroundColor: "solid",
                            borderRadius: "8px",
                        },
                    },
                }}
            />
            {openDropdown && filteredCompanies.length > 0 && (
                <Box
                    ref={dropdownRef}
                    sx={{
                        position: 'absolute',
                        top: '100%', // Position below the search bar
                        left: 0,
                        width: '100%',
                        maxHeight: 200, // Adjust as needed
                        overflowY: 'auto',
                        backgroundColor: theme.palette.background.paper,
                        boxShadow: 3,
                        borderRadius: '8px',
                        zIndex: 10, // Ensures it floats above other content
                    }}
                >
                    <List height={200} itemCount={filteredCompanies.length} itemSize={50} width="100%">
                        {Row}
                    </List>
                </Box>
            )}
        </Box>
    );

};

export default CompanySearch;
