import React, { useContext, useEffect, useState } from 'react';
import { Grid, Typography, Box, Card, Tab, Tabs, Radio, RadioGroup, FormControlLabel, Switch } from '@mui/material';
import CompanySearch from '../../components/common/CompanySearch.jsx';
import HomePageContext from '../../context/HomePageContext.jsx';
import ScreenerChart from '../../components/Chart/ScreenerChart.jsx';
import OpenAiAnalyze from '../../components/HomeDashboard/OpenAiAnalyzeCard.jsx';
import FsListTable from '../../components/HomeDashboard/FundFilterDataGrid.jsx';
import TsListTable from '../../components/HomeDashboard/TechFilterDataGrid.jsx';
import IndexScreenerChart from '../../components/Chart/IndexScreenerChart.jsx';

const HomeComponent = () => {
  const handleSwitchChange = (event) => {
    setType(event.target.checked ? "stock" : "index");
  };

  const {
    selectedCompany,
    companyList,
    indexList,
    handleSearchSelect,
    nseCompanyData,
    indexData,
    type,
    setType
  } = useContext(HomePageContext);

  return (
    <Grid>
      <Card>
        <Typography variant="h4" sx={{ color: '#333', marginBottom: 1 }}>
          AI-Based Company Share Analysis
        </Typography>
        <Typography variant="body1" sx={{ color: '#555' }}>
          Leverage AI to analyze company performance based on technical and fundamental data.
        </Typography>
      </Card>
      <Grid item xs={12} md={6} lg={8} container alignItems="center"
        paddingBottom={2}
        paddingTop={2}
      >
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
        // paddingLeft={2}
        >

          <FormControlLabel
            control={<Switch checked={type === "stock"} onChange={handleSwitchChange} />}
            label={
              <Typography variant="body1" style={{ fontWeight: 'bold' }}>
                {type === "index" ? "Index" : "Stock"}
              </Typography>
            }
            labelPlacement="start"
          />
        </Box>
        <Grid
          item xs={6} md={8} lg={6}
          paddingLeft={2}
        >
          <CompanySearch
            handleSearchSelect={handleSearchSelect}
            companyList={type === "stock" ? companyList : indexList}
            type={type}
          />
        </Grid>

      </Grid>

      {type === "stock" && nseCompanyData ? (
        <Grid>
          <Box
            sx={{
              position: 'relative',
              width: '100%',
              borderRadius: '8px',

            }}
          >
            <ScreenerChart nseCompanyData={nseCompanyData} />
          </Box>

          <Box
            sx={{
              marginBottom: 2,
              marginTop: 2,

              borderRadius: '8px',
            }}>
            <OpenAiAnalyze company={selectedCompany} />
          </Box>
        </Grid>
      ) : (
        <Grid>
          <Box
            sx={{
              position: 'relative',
              width: '100%',
              borderRadius: '8px',
            }}
          >
            <IndexScreenerChart indexData={indexData} />
          </Box>
        </Grid>
      )}

      <Typography variant="h4" sx={{ color: '#333', marginBottom: 1 }}>
        Technically Bullish
      </Typography>
      <Box sx={{
        overflowX: 'auto',
        borderRadius: '8px',
      }}>
        <TsListTable />
      </Box>

      <Box>

        <Typography variant="h4" sx={{ color: '#333', marginBottom: 1 }}>
          Fundamental-Score
        </Typography>
        <Box sx={{
          overflowX: 'auto',
          borderRadius: '8px',
          paddingBottom: 3
        }}>
          <FsListTable />
        </Box>
      </Box>
    </Grid>
  );

};

export default HomeComponent;
