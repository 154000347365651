import React, { useEffect, useRef, useState, useMemo, useContext } from "react";
import ChartJS from "../../config/chartJsConfig";
import { fetchIndexData } from "../../utils/services";
import { getToken } from "../../utils/helpers";
import { Button, Box, Card, CssBaseline } from "@mui/material";
import HomePageContext from "../../context/HomePageContext";
import AppTheme from "../../theme/AppTheme";
import {
  chartsCustomizations,
  dataGridCustomizations,
  datePickersCustomizations,
  treeViewCustomizations,
} from "../../theme/customizations";

const xThemeComponents = {
  ...chartsCustomizations,
  ...dataGridCustomizations,
  ...datePickersCustomizations,
  ...treeViewCustomizations,
};


const IndexScreenerChart = ({ indexData }) => {

  const {
    selectedIndex,
    // setSelectedIndex
  } = useContext(HomePageContext);


  const authToken = getToken();
  const chartRef = useRef(null);
  const chartInstance = useRef(null);

  const [activeDays, setActiveDays] = useState(365);
  const [activeMetric, setActiveMetric] = useState("volume");
  const [cData, setChartData] = useState(indexData || []);

  const sortedData = useMemo(() => {
    return [...cData].sort(
      (a, b) => new Date(a.createdAt) - new Date(b.createdAt)
    );
  }, [cData]);

  const uniqueData = useMemo(() => {
    const seenDates = new Set();
    return sortedData.filter((item) => {
      const formattedDate = new Date(item.createdAt).toLocaleDateString("en-GB");
      if (!seenDates.has(formattedDate)) {
        seenDates.add(formattedDate);
        return true;
      }
      return false;
    });
  }, [sortedData]);

  const getMetricData = useMemo(() => {
    return uniqueData.map((item) =>
      activeMetric === "volume" ? parseFloat(item.volume) : parseFloat(item.turnover)
    );
  }, [uniqueData, activeMetric]);

  const chartOptions = useMemo(() => ({
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        grid: { display: false },
        title: { display: true, text: "Date" },
      },
      y: {
        title: { display: true, text: "Price" },
        grid: { drawBorder: false },
        ticks: { beginAtZero: true },
      },
      "metric-axis": {
        position: "right",
        title: { display: true, text: activeMetric },
        grid: { drawOnChartArea: false },
        ticks: {
          beginAtZero: true,
          callback: (value) =>
            value >= 1000 ? `${(value / 1000).toFixed(0)}K Cr` : `${value} Cr`,
        },
      },
    },
    plugins: {
      legend: {
        position: "top",
        labels: { usePointStyle: true },
      },
      tooltip: { mode: "index", intersect: false },
    },
  }), [activeMetric]);

  const chartData = useMemo(() => ({
    labels: uniqueData.map((item) =>
      new Date(item.createdAt).toLocaleDateString("en-GB")
    ),
    datasets: [
      {
        label: "Price",
        data: uniqueData.map((item) => item.close),
        backgroundColor: "rgb(173, 170, 216)",
        borderColor: "rgba(173, 170, 216, 1)",
        borderWidth: 2.5,
        tension: 0.3,
        pointRadius: 0,
      },
      {
        label: activeMetric,
        type: "bar",
        data: getMetricData,
        yAxisID: "metric-axis",
        backgroundColor: "rgb(190, 211, 253)",
        barPercentage: 0.6,
      },
    ],
  }), [uniqueData, activeMetric, getMetricData]);

  useEffect(() => {

    if (!selectedIndex)
      return
    const fetchData = async () => {
      try {
        const today = new Date();
        const startDate = new Date();
        startDate.setDate(today.getDate() - activeDays);
        const formattedStartDate = startDate.toISOString().split("T")[0];
        const formattedEndDate = today.toISOString().split("T")[0];
        const data = await fetchIndexData(
          authToken,
          selectedIndex.symbol,
          formattedStartDate,
          formattedEndDate
        );
        console.log('data', data)
        setChartData(data.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, [authToken, activeDays, selectedIndex]);

  useEffect(() => {
    if (chartInstance.current) chartInstance.current.destroy();
    if (!chartData) return;

    // setSelectedIndex(indexData[0]?.index);

    const ctx = chartRef.current.getContext("2d");
    chartInstance.current = new ChartJS(ctx, {
      type: "line",
      data: chartData,
      options: chartOptions,
    });
    return () => chartInstance.current.destroy();
  }, [chartData, chartOptions]);


  return (
    <AppTheme themeComponents={xThemeComponents}>
      <CssBaseline enableColorScheme />
      <Card sx={{ height: "100%" }}>
        <Box
          id="index-chart-menu"
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "16px",
            flexWrap: { xs: "wrap", md: "nowrap" }, // Wrap on smaller screens
            gap: "16px",
            // border:1,
            // background:'white'
          }}
        >
          <Box
            id="index-chart-days"
            className="options"
            sx={{
              display: "flex",
              flexWrap: "wrap", // Allow wrapping on smaller screens
              gap: "4px",
            }}
          >
            {[
              { label: "1w", value: 7 },
              { label: "1m", value: 30 },
              { label: "3m", value: 90 },
              { label: "6m", value: 180 },
              { label: "1Yr", value: 365 },
              { label: "3Yr", value: 1095 },
              { label: "5Yr", value: 1825 },
              { label: "Max", value: 10000 },
            ].map((item) => (
              <Button
                key={item.value}
                name="days"
                value={item.value}
                onClick={() => setActiveDays(item.value)}
                variant={activeDays === item.value ? "contained" : "outlined"}
                size="small"
                sx={{
                  margin: "4px 0",
                  minWidth: "64px", // Ensure buttons have a consistent minimum size
                  textTransform: "none", // Keep button labels readable
                  fontSize: { xs: "10px", sm: "12px", md: "14px" }, // Adjust font size for smaller screens
                }}
              >
                {item.label}
              </Button>
            ))}
          </Box>
          <Box
            id="index-chart-metrics"
            className="options"
            sx={{
              display: "flex",
              flexWrap: "wrap", // Allow wrapping on smaller screens
              gap: "4px",
              // background:'white'
            }}
          >
            {[
              { label: "Volume", value: "volume" },
              { label: "Turnover", value: "turnover" },
            ].map((item) => (
              <Button
                key={item.value}
                onClick={() => setActiveMetric(item.value)}
                variant={activeMetric === item.value ? "contained" : "outlined"}
                name="metrics"
                value={item.value}
                size="small"
                sx={{
                  margin: "4px 0",
                  minWidth: "64px",
                  textTransform: "none",
                  fontSize: { xs: "10px", sm: "12px", md: "14px" },
                }}
              >
                {item.label}
              </Button>
            ))}
          </Box>
        </Box>
        <Box
          className="flex no-select"
          sx={{
            display: "flex",
            alignItems: "center",
            flexGrow: 1,
            // background:'white'
          }}
        >
          <canvas
            ref={chartRef}
            id="chart-canvas"
            style={{ width: "100%", height: "375px" }}
          >
            
          </canvas>
        </Box>
      </Card>
    </AppTheme>
  );
};

export default IndexScreenerChart;

