import { useEffect, useState } from 'react';
import Card from '@mui/material/Card';
import { Grid, Box } from '@mui/material';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded';
import InsightsRoundedIcon from '@mui/icons-material/InsightsRounded';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { fetchAiInsights ,fetchAverageScore} from '../../utils/services';
import { getToken } from '../../utils/helpers';
import RatingComponent from '../common/RatingComponent';
import SignUpPopup from '../../pages/SignUp/SignUp';
// import Stack from '@mui/material/Stack';
// import { message } from 'antd';

export default function OpenAiAnalyze({ company }) {
  const authToken = getToken();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [AiInsights, setInsights] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const [Scores, setScores] = useState([]);
  const [year, setYear] = useState(2);
  const [initialYear,setInitialYear] = useState(2);
  const finYear = new Date().getFullYear() - year;
  const [openSignup, setOpenSignup] = useState(false); // State for managing the popup


  const handleGetInsights = () => {
    if(!authToken)
      setOpenSignup(true);
    else{
      if(authToken && company?.isin) {
        setLoading(true);
        setError(null);
        fetchAiInsights(authToken, company.isin)
          .then((data) => {
            setInsights(data); 
            setLoading(false);
          })
          .catch((error) => {
            setError('Error fetching insights');
            setLoading(false);
          });
      } else {
        alert("Search a company first");
      }
    }
  };

  useEffect(() => {
    setInsights(null);
    setError(null);
    setLoading(false);
    setYear(2)
  }, [company]);

  useEffect(() => {
    if (!company?.symbol) return;

    const fetchData = async () => {
      try {
        const data = await fetchAverageScore(authToken, company.symbol, finYear);
        setScores(data || []);
      } catch (error) {
        console.error('Error fetching scores:', error);
        setScores([]);
      }
    };
    fetchData();
  }, [company, finYear]);

  return (
    <Box sx={{ width: '100%' }}>
      <Card sx={{ height: '100%' }}>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>

              {company && (
                <>
              <Typography variant="h6" sx={{ marginBottom: 3, fontWeight: 'bold' }}>
                Company Info
              </Typography>
                  <Typography variant="subtitle2" sx={{ display: 'block', marginBottom: 1 }}>
                    <strong>Company:</strong> {company.name}
                  </Typography>
                  <Typography variant="subtitle2" sx={{ display: 'block', marginBottom: 1 }}>
                    <strong>Symbol:</strong> {company.symbol}
                  </Typography>
                  <Typography variant="subtitle2" sx={{ display: 'block', marginBottom: 1 }}>
                    <strong>Exchange:</strong> {company.exch}
                  </Typography>
                  <Typography variant="subtitle2" sx={{ display: 'block', marginBottom: 1 }}>
                    <strong>ISIN:</strong> {company.isin}
                  </Typography>
                </>
              )}
            </Grid>
  
            <Grid item xs={12} md={6} paddingRight={2} sx={{ marginTop: -2 }}>
                <RatingComponent Scores={Scores} year={year} setYear={setYear} initialYear={initialYear} />

            </Grid>
          </Grid>
  
          {/* Get insights Button */}
          <Typography component="h2" variant="subtitle2" gutterBottom sx={{ fontWeight: '600', marginTop: 3, marginBottom: 1 }}>
            Get AI Insights with Data
          </Typography>
  
          <Button
            variant="contained"
            size="small"
            color="primary"
            onClick={handleGetInsights}
            endIcon={<ChevronRightRoundedIcon />}
            fullWidth={isSmallScreen}
          >
            Get insights
          </Button>
          {openSignup && <SignUpPopup open={openSignup} setOpen={setOpenSignup} />}
  
          {loading && <Typography sx={{ mt: 2 }}>Loading insights...</Typography>}
          {error && <Typography sx={{ mt: 2, color: 'error.main' }}>{error}</Typography>}
  
          {AiInsights && (
            <Box sx={{ mt: 3 }}>
              <Typography variant="h6">Overall Analysis</Typography>
              <Typography sx={{ mb: 2 }}>{AiInsights.overallAnalysis}</Typography>
  
              <Typography variant="h6">Pros</Typography>
              <ul>
                {AiInsights.pros.map((pro, index) => (
                  <li key={index}>
                    <Typography>{pro}</Typography>
                  </li>
                ))}
              </ul>
  
              <Typography variant="h6">Cons</Typography>
              <ul>
                {AiInsights.cons.map((con, index) => (
                  <li key={index}>
                    <Typography>{con}</Typography>
                  </li>
                ))}
              </ul>
  
              <Typography variant="h6">Recommendations</Typography>
              <Typography>{AiInsights.recommendations}</Typography>
            </Box>
          )}
        </CardContent>
      </Card>
    </Box>
  );
  
}
