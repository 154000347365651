// context/CompanySearchContext.js
import React, { createContext, useContext, useState ,useEffect} from 'react';

import { useSearchParams } from 'react-router-dom';

const CompanySearchContext = createContext({
  searchQuery: '',
  setSearchQuery: () => {},
  companySymbol: '',
  setCompanySymbol: () => {},
  indexName: '',
  setIndexName: () => {},
  ISIN: '',
  setCompanyISIN: () => {},
  searchParams: null,
  setSearchParams: () => {},
  
});

export const useCompanySearch = () => {
  return useContext(CompanySearchContext);
};

export const CompanySearchProvider = ({ children }) => {
  // const [searchQuery, setSearchQuery] = useState('');
  // const [companySymbol, setCompanySymbol] = useState('');
  // const [ISIN, setCompanyISIN] = useState('');
  const [searchParams, setSearchParams] = useSearchParams();
  const [searchQuery, setSearchQuery] = useState(searchParams.get('query') || '');
  const [companySymbol, setCompanySymbol] = useState(searchParams.get('symbol') || '');
  const [ISIN, setCompanyISIN] = useState(searchParams.get('isin') || '');
  const [indexName,setIndexName] = useState(searchParams.get('indexName') || '');
  useEffect(() => {
    const params = {};
    if (searchQuery) params.query = searchQuery;
    if (companySymbol) params.symbol = companySymbol;
    if (ISIN) params.isin = ISIN;
    if (indexName) params.indexName = indexName;
    setSearchParams(params);
  }, [searchQuery, companySymbol, ISIN, setSearchParams]);


  return (
    <CompanySearchContext.Provider value={{ 
            searchQuery, setSearchQuery, 
            companySymbol, setCompanySymbol,
            ISIN ,setCompanyISIN,
            searchParams,setSearchParams,
            indexName,setIndexName
            }}>
      {children}
    </CompanySearchContext.Provider>
  );
};
